export const headerAction = () => {
  $(function () {
    const $body = $("body");

    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 0) {
        $body.addClass("is_header-color");
      } else {
        $body.removeClass("is_header-color");
      }
    });
  });
};
