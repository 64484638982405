export function inview() {
  $(function () {
    const $jsFadeUp = $(".js-fade-up");
    const $jsFadeLeft = $(".js-fade-left");
    const $jsFadeRight = $(".js-fade-right");

    $jsFadeUp.on("inview", function () {
      $(this).stop().addClass("is-show");
    });

    $jsFadeLeft.on("inview", function () {
      $(this).stop().addClass("is-show");
    });

    $jsFadeRight.on("inview", function () {
      $(this).stop().addClass("is-show");
    });
  });
}
