// export 文を使って関数 greet1 を定義
export const loadingAni = () => {
  $(window).bind("load", function () {
    setTimeout(() => {
      // console.log("move");
      $("html").removeClass("is_loadding");
      $("body").addClass("is-MV-show");
    }, 1000);
  });
};
