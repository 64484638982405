export const qandaMark = () => {
  $(function () {
    const $qandaMark = $(".js-qanda-target");

    $qandaMark.on("click", function () {
      $(this).parent().toggleClass("is-open");
      $(this).parent().find(".p-qanda-content__content-item-a").slideToggle();
    });
  });
};
