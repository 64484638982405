import { inview } from "./assets/js/modules/inview.js";
import { hambergerToggle } from "./assets/js/modules/hamburgertoggle.js";
import { topSlider } from "./assets/js/modules/top-slider.js";
import { qandaMark } from "./assets/js/modules/qanda-mark.js";
import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";
import { accordion } from "./assets/js/modules/accordion.js";
import { initPcNav } from "./assets/js/modules/pcNav.js";
import { spNavDropToggle } from "./assets/js/modules/spDropToggle.js";
import { resizeAction } from "./assets/js/modules/resize-action.js";
import { tabBtn } from "./assets/js/modules/tab-btn.js";
import { cookie } from "./assets/js/modules/cookie.js";
import { loadingAni } from "./assets/js/modules/loadding-ani.js";
import { jobChart } from "./assets/js/modules/jobChart.js";
import { ageChart } from "./assets/js/modules/ageChart.js";
import { postChart } from "./assets/js/modules/postChart.js";
import { accordionMore } from "./assets/js/modules/accordionMore.js";
import { headerAction } from "./assets/js/modules/header-action.js";

// 375px未満は縮小
// import { adjustViewportAction } from "./assets/js/modules/adjustViewport.js";

import { matchMediaTest } from "./assets/js/modules/matchMedia.js";

const $body = $("body");
spNavDropToggle();
initPcNav();
inview();
hambergerToggle();
pageTopBtnAction();
qandaMark();
accordion();
cookie();
loadingAni();
// adjustViewportAction();
matchMediaTest();
headerAction();

if ($body.hasClass("top")) {
  resizeAction();
  topSlider();
  // jobChart();
  // ageChart();
  // postChart();
}

if ($body.hasClass("service")) {
  resizeAction();
  topSlider();
  jobChart();
  ageChart();
  postChart();
  accordionMore();
}

if ($body.hasClass("support")) {
  accordionMore();
}

if ($body.hasClass("recruit")) {
  tabBtn();
}
