export const accordionMore = () => {
  $(function () {
    $(".js-accordion-more").each(function () {
      $(this).click(function () {
        $(this).toggleClass("is-open");
        $(".js-accordion-target").stop().slideToggle();
      });
    });
  });
};
