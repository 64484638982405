export const topSlider = () => {
  $(function () {
    // mvのスライダー
    $(".js-mv-slider").slick({
      autoplay: true,
      // autoplay: false,
      autoplaySpeed: 4000,
      speed: 5000,
      fade: true,
      arrows: false,
    });

    function voiceSlider() {
      var width = $(window).width();

      if (width < 769) {
        // voiceのスライダー
        $(".js-top-voice-slider").slick({
          autoplay: true,
          // autoplay: false,
          centerMode: true,
          centerPadding: "13%",
          arrows: true,
          autoplaySpeed: 5000,
          speed: 1000,
          appendArrows: $(".js-slider__arrows"),
          prevArrow: '<div class="slide-arrow prev-arrow"></div>',
          nextArrow: '<div class="slide-arrow next-arrow"></div>',
        });
      }
    }

    voiceSlider();

    $(window).resize(function () {
      voiceSlider();
    });
  });
};
