export const tabBtn = () => {
    $(function () {
        const body = $('body');

        const $jsTabBtn = $('.js-tab-btn');

        const $jsTabTarget = $('.js-tab-target');

        console.log('move');

        $jsTabBtn.on('click', function () {
            $jsTabBtn.removeClass('is-btn-active');
            let n = $(this).attr('data-no');
            $(this).addClass('is-btn-active');

            $jsTabTarget.each(function () {
                $(this).removeClass('is-active');
                if ($(this).attr('data-no') == n) {
                    $(this).addClass('is-active');
                }
            });
        });
    });

    $(function () {
        const $jsTabBtn = $('.js-tab-btn-cus');

        const $jsTabTarget = $('.js-tab-target-cus');

        $jsTabBtn.on('click', function () {
            $jsTabBtn.removeClass('is-active');
            let n = $(this).attr('data-no');
            $(this).addClass('is-active');

            $jsTabTarget.each(function () {
                $(this).removeClass('is-active');
                if ($(this).attr('data-no') == n) {
                    $(this).addClass('is-active');
                }
            });
        });
    });
};
