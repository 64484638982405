import Chart from "chart.js/auto";

export const postChart = () => {
  $(function () {
    const ctx = document.getElementById("postChart");

    const config = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [61, 29, 10],
            backgroundColor: ["#9A8E75", "#B3AA97", "#C2BBAC"],
            borderWidth: 0, // 境界線の幅を0に設定して縁を無くす
          },
        ],
      },
      options: {
        cutout: getCutoutPercentage(), // 内側の円の半径を50%に設定（太さを調整）
        events: [], // チャート上の全てのイベントを無効にする
      },
    };

    // スマートフォンの場合に内側の円の大きさを取得する関数
    function getCutoutPercentage() {
      // スマートフォンの画面幅は通常 767px 以下と考えてメディアクエリを設定
      if ($(window).width() <= 767) {
        return "92%"; // 内側の円の大きさを70%に設定
      } else {
        return "90%"; // それ以外の場合は80%に設定
      }
    }

    let chart; // チャートインスタンスを保存する変数

    $(".js-chart").on("inview", function (event, isInView) {
      if (isInView) {
        // 既存のチャートが存在する場合は破棄する

        setTimeout(() => {
          if (chart) {
            return;
          }
          // チャートを作成する
          chart = new Chart(ctx, config);
          $(this).addClass("is-show");
        }, 300);
      }
    });
  });
};
