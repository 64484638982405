export const accordion = () => {
    $(function () {
        $('.js-accordion__ttl').each(function () {
            $(this).click(function () {
                $(this).toggleClass('is-open');
                $(this).parent().toggleClass('active');
                $(this).next().stop().slideToggle();
            });
        });
    });
};
