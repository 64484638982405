import gsap from "gsap";

const navItems = document.querySelectorAll(".l-header__menu-item");
const navPc = document.getElementsByClassName("l-header__menu")[0];

export const initPcNav = () => {
  navItems.forEach((item) => {
    const dropdown = item.querySelector(".l-header__menu-drop-down");

    item.addEventListener("mouseenter", () => {
      if (dropdown) {
        item.classList.add("is-drop-open");
        navPc.classList.add("is-drop-open");
        gsap.to(dropdown, {
          duration: 0.3,
          height: "auto",
          ease: "power1.out",
        });
      }
    });
    item.addEventListener("mouseleave", () => {
      if (dropdown) {
        item.classList.remove("is-drop-open");
        navPc.classList.remove("is-drop-open");
        gsap.to(dropdown, { duration: 0.3, height: 0, ease: "power1.out" });
      }
    });
  });
};
